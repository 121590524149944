/*  scss file  */

.off-canvas {
    margin-top: map-get($app-navbar-heights, small);
    font-weight: bold;

    @include breakpoint(medium) {
        margin-top: map-get($app-navbar-heights, medium);
        }

    @include breakpoint(large) {
        margin-top: map-get($app-navbar-heights, large);
        }

    .mobilemenu {
        background-color: $purple-dark;
        }

    .menu {

        a {
            color: white;
            }
        .active a {
            color: $purple-dark;
            background-color: white;
            }
        }
    }



