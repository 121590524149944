/*  scss file  */

.blog{

    .title{
        margin:rem-calc(10) 0;
        }

    }

.post {
  padding: 30px 0;
  border-bottom: 1px solid $wp-lightergrey;


}

#sidemenu {


  li {
    list-style-type: none;
    padding: 0 0 10px 0;
  }

  .small {
    font-size: 12px;
  }
}

