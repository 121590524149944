/*  scss file  */

.jumbotron {
    position: relative;

    background-image: linear-gradient($purple 90%, darken($purple,5%));
    background-position: bottom;
    background-repeat: repeat-x;

    .bgpicture {
        background: url(/assets/img/hero_sans.jpg);
        background-size: cover;
        }

    .logocontainer {
        position: relative;
        }
    }

.masthead {
    color: #FFFFFF;
    margin-bottom: 0;
    background: url(/assets/img/mastheadBG.gif) repeat-x;
    height: map-get($app-masthead-heights, small)-10;
    transition: height 0.5s;

    @include breakpoint(medium) {
        height: map-get($app-masthead-heights, medium)-10;
        }

    @include breakpoint(large) {
        height: map-get($app-masthead-heights, large)-10;
        }
    }

.logo {
    background-image: url(/assets/img/logoribbon.png);
    background-size: cover;
    background-position: left bottom;
    position: absolute;
    left: rem-calc(20);
    width: 105px;
    height: map-get($app-masthead-heights, small);
    transition: height 0.5s;

    @include breakpoint(medium) {
        //background-size: initial;
        height: map-get($app-masthead-heights, medium);
        width: 145px;
        }

    @include breakpoint(large) {
        //background-size: initial;
        left: rem-calc(50);
        height: map-get($app-masthead-heights, large);
        width: 193px;
        }
    }

.vervolg {

    .logo {
        background-image: url(/assets/img/logoribbon-small.png);
        }

    $ARR : $app-smallmasthead-heights;

    .masthead {
        height: map-get( $ARR, small)-10;

        @include breakpoint(medium) {
            height: map-get( $ARR, medium)-10;
            }

        @include breakpoint(large) {
            height: map-get( $ARR, large)-10;
            }
        }

    .logo {
        height: map-get( $ARR, small);

        @include breakpoint(medium) {
            height: map-get( $ARR, medium);
            }

        @include breakpoint(large) {
            height: map-get( $ARR, large);
            }
        }
    }


