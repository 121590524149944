/*  scss file  */

.footer {
    //background-image: linear-gradient(darken($purple,5%), $purple 10%);
    //background-image:url('/assets/img/footer-bg.jpg') ;
    margin-top: 64px;
    background-color: $purple;
    min-height: 200px;

    .container {

        .container-bg{
            //background-color: rgba(12,117,161,.5);
            display: block;
        }


        &:first-child{
            padding: 30px 20px 0px 0px;
        }

        color: #fff;
        li{
            list-style: none;
            font-weight: bold;
            padding:2px 0;
        }

        a, a:visited {
            color: #fff;

            &:hover{
                text-decoration: underline;
            }
        }

    }
}