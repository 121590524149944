/*  scss file  */

.tip {
    background-color: $purple-dark !important;
    color: white !important;
    width:25%;
    //&.place-top {
    //    &:after {
    //        border-top-color: $purple-dark !important;
    //        border-top-style: solid !important;
    //        border-top-width: 6px !important;
    //        }
    //    }
    }

.mobile-tip{
    font-size:rem-calc(14);
    color:$white;
    text-align: left;
    }