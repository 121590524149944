/*  scss file  */

.boomframe {
  border-top: 1px dotted #000;
  border-bottom: 1px dotted #000;
  padding: 10px 0;

  .boom-container {
    width: 100%;
    overflow: hidden;
    height: 380px;
    position: relative;
  }
  .card {
    position: absolute;
    width: 100%;
    min-height: 450px;
  }
  .box {
    border: 1px solid #aaa;
    border-radius: 8px;
    padding: 20px;
  }
  .vraag {
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
  }
  .antwoord-container {
    padding: 2px;
    box-shadow: 0 4px 4px #ccc;
    border-radius: 10px;
    margin: 10px 0;
  }

  .antwoord {
    color: #fefefe;
    background: linear-gradient(180deg, #73c5e8, #1b7da7);
    border: 2px solid #fff;
    border-radius: 8px;
    padding: 20px;
    cursor: pointer;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      background: #d4d4d4;
      z-index: -1;
      border-radius: 10px;
    }
  }
  .voortganglabel {
    font-size: 0.75rem;
    font-weight: 300;
  }
  .boomframe .modal {
    position: absolute;
    color: #fff;
    background-color: rgba(32, 17, 86, 0.9);
    padding: 10px 10px 30px;
    height: 100%;
    display: block;
    width: 100%;
    z-index: 10;
  }

  .mobile-tooltip {
    font-size: 0.8rem;
  }

  .infobtn {
    color: #0c75a1;
  }
  .modal {
    position: absolute;
    color: #fff;
    background-color: rgba(32, 17, 86, 0.9);
    padding: 10px 10px 30px;
    height: 100%;
    display: block;
    width: 100%;
    z-index: 10;
  }
  .groen {
    background-color: #2ead00;
    color: #fff;
  }

  .oranje {
    background-color: orange;
    color: #fff;
  }
  .rood {
    background-color: #bd1600;
    color: #fff;
  }
}

.boomframe .infobtn,
.boomframe .modal .closebtn {
  display: block;
  width: 100%;
  text-align: right;
  cursor: pointer;
}
