/*  scss file  */

.navbar {
    background: $purple-lighter;
    border-bottom: 1px solid #d4d4d4;
    height: map-get($app-navbar-heights, small);
    width: 100%;
    position: absolute;
    top: 0px;
    transition: height 0.5s;
    z-index: 999;

    .logo-row {
        @include grid-column(9);
        padding-top: rem-calc(14);
        }

    .menu-row {
        @include grid-column(1);
        @include show-for(large);
        }

    .toggle-row {
        @include grid-column(12);
        @include hide-for(large);
        }

    @include breakpoint(medium) {
        height: map-get($app-navbar-heights, medium);

        &.small {
            height: 80px;
            }

        .logo-row {
            @include grid-column(3);
            padding-top: rem-calc(18); }

        .menu-row {
            @include grid-column(9);
            }

        .toggle-row {
            @include grid-column(12);
            }
        }

    @include breakpoint(large) {
        height: map-get($app-navbar-heights, large);

        &.small {
            height: 80px;
            }

        .logo-row {
            @include grid-column(0);
            // padding-top: rem-calc(18);
            display: none;
            }

        .menu-row {
            @include grid-column(12);
            }

        .toggle-row {
            @include grid-column(1);
            }
        }

    .responsive-menu-toggle {
        color: $white;
        font-size: rem-calc(24);
        margin: auto 0;
        padding: 7px 10px;
        }

    .woordbeeld{
        color:#fff;
        padding:15px 0;
        font-size:1rem;
        font-family: $header-font-family;
        font-weight:bold;

        @include breakpoint(medium) {
            padding:10px 0;
            font-size:1.4rem;
            }

        }

    }

.logo {
    background: url("/assets/eom/img/eom-logo-mobile.png") no-repeat;
    width: 100%;
    height: 40px;
    background-size: contain;

    @include breakpoint(medium) {
        background-image: url("/assets/eom/img/eom-logo.png");
        width: 100%;
        height: 80px;
        }

    @include breakpoint(large) {
        background-image: url("/assets/eom/img/eom-logo.png");
        width: 100%;
        height: 100px;
        }

    }

.mainmenu {

    li {
        padding: 10px 15px 10px;

        @include breakpoint(medium) {
            padding: 10px 15px 10px;
            }

        @include breakpoint(large) {
            padding: 10px 15px 10px;
            }

        a {
            color: #fff;
            padding: 0;
            }

        }

    .is-submenu-item {

        a {
            line-height: 1.5rem;
            }

        }

    }