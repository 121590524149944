.homeCol {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 10px 10px 40px 10px;
  position: relative;
  margin-bottom: 10px;
}
.homeCol .btn {
  position: absolute;
  bottom: 10px;
}
