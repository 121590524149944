.card {
  border: 1px solid #eee;
  border-radius: 5px;
  flex: 1 1 25%;
  gap: 1rem;
  position: relative;

  @include breakpoint(medium) {
  }

  &__row {
    display: grid;
    flex-flow: column;
    gap: 1rem;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding: 10px;

    @include breakpoint(medium) {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      padding: 0;
    }

    &.stacked {
      display: grid;
      @include breakpoint(medium) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include breakpoint(large) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__image {
    img {
      aspect-ratio: 16/7;
      border-radius: 5px 5px 0 0;
      object-fit: cover;
      width: 100%;
    }
  }

  &__content {
    margin-bottom: 10px;
    padding: 10px 10px 40px 10px;
  }

  &__title {
    color: #000;
    font-size: 18px;
    font-weight: bold;
  }

  a {
    display: inline-block;
    background-color: #1779ba;
    color: #fff;
    width: auto;
    padding: 10px 20px;
    border-radius: 4px;
  }

  iframe {
    aspect-ratio: 16/9;
  }
}
